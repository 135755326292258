<template>
  <div>下载中，请稍后... ({{ this.loadPercentage }})</div>
</template>

<script>
import $store from '../store'

export default {
  computed: {
    loadPercentage () {
      return $store.state.loadPercentage
    }
  }
  
}
</script>

<style scoped>
  div {
    color: #fcfcfc;
    position: absolute;
    left: -65%;
    margin-top: 10px !important;
    width: 200px
  }
</style>
