<template>
  <div class="apps">
    <v-dialog />
    <div>
      <section class="l-content-width section section-hero">
        <div style="display: inline-block;width: 31vw;vertical-align: top;">
          <div id="appdetails-icon" class="appdetails-icon small-5" style="width: 100%;">
            <div class="ios-app-icon">
              <img id="appIcon" class="ios-app-icon-img" style="background-color: #fefefe;" alt="" v-bind:src="iconUrl" />
            </div>
          </div>
        </div>
        <div style="display: inline-block;width: 52vw;vertical-align: top;margin-left: 8px;">
          <div>
            <div class="ios-app-brief">
              <header class="ios-app-brief-header">
                <h1>
                  {{ bifrost.cname }}
                </h1>
                <h2 class="ios-app-vendor">
                  <a href="javascript:;" style="color:rgba(132,132,137,1);">cjtuzi.com</a>
                </h2>
              </header>
            </div>
          </div>
          <div>
            <div style="position: absolute;">
              <ul class="ios-app-recom" style="width:100%;">
                <div id="installText" style="display:none;margin-top:-20px;">
                </div>
                <button @click="downloadAPK" class="downloadBtn">安装</button>
              </ul>
            </div>
            <div class="ios-app-install-btn download"
              style="width: 28px;position:relative;float: right;right: 10px;visibility:hidden">?</div>
          </div>
          <p id="tips"></p>
        </div>
      </section>

      <section class="l-content-width">
        <div class="ios-app-rating" style="width:45%">
          <div class="ios-app-rating-caption-big plxing">
            4.9
            <p>
              <img src="../assets/img/xxing.png" alt="" />
              <img src="../assets/img/xxing.png" alt="" />
              <img src="../assets/img/xxing.png" alt="" />
              <img src="../assets/img/xxing.png" alt="" />
              <img src="../assets/img/xing2.png" alt="" />
            </p>
          </div>
          <h5 class="ios-caption-subtitle" style="color: #ababae;">298 个评分</h5>
        </div>
        <div class="ios-app-rating" style="width:25%; text-align: center;">
          <div class="ios-app-rating-caption-big">
            <font style="vertical-align: middle;font-size: 14px;font-weight: 600;">#</font>1
          </div>
          <h5 class="ios-caption-subtitle" style="color: #ababae;">快应用</h5>
        </div>
        <div class="ios-app-rating" style="width:25%; text-align: right;">
          <div class="ios-app-rating-caption-big">18+</div>
          <h5 class="ios-caption-subtitle" style="color: #ababae;">年龄</h5>
        </div>
      </section>

      <section class="l-content-width section section-bordered">
        <div class="ellipsis">
          <div class="ellipsis-container">
            <div class="ellipsis-content f-game-content" style="color: #2e2e2e;font-size: 14.3px;">
              <p>【{{ bifrost.cname }}】 cjtuzi推荐APP</p>
            </div>
            <!-- <div id="expand" class="ellipsis-ghost expand"> -->
            <!-- <div class="ellipsis-ghost"> -->
            <div>
              <div class="ellipsis-more">更多</div>
            </div>
          </div>
        </div>
      </section>

      <section class="l-content-width section section-bordered" style="padding-bottom:0;padding-top: 14px;">
        <h2 class="section-headline" style="font-size:22px;color:#000;margin-bottom: 10px;">评分及评论</h2>
        <div class="ios-app-cust-ratings" style="margin-bottom:0;">
          <div class="ios-app-cust-ratings_stats">
            <h3 class="ios-app-ratings_avg">
              <span class="ios-app-ratings-avg-disp">4.9</span>
              满分 5 分
            </h3>
          </div>
          <div class="ios-app-ratings-bars">
            <figure>
              <div class="ios-app-ratings-bar-row">
                <span class="ios-app-rating-stargraph-stars ios-app-rating-stargraph-stars-5"></span>
                <div class="ios-app-rating-bargraph-bar">
                  <div class="ios-app-rating-bargraph-bar-foreground" style="width: 90%;"></div>
                </div>
              </div>
              <div class="ios-app-ratings-bar-row">
                <span class="ios-app-rating-stargraph-stars ios-app-rating-stargraph-stars-4"></span>
                <div class="ios-app-rating-bargraph-bar">
                  <div class="ios-app-rating-bargraph-bar-foreground" style="width: 5%;"></div>
                </div>
              </div>
              <div class="ios-app-ratings-bar-row">
                <span class="ios-app-rating-stargraph-stars ios-app-rating-stargraph-stars-3"></span>
                <div class="ios-app-rating-bargraph-bar">
                  <div class="ios-app-rating-bargraph-bar-foreground" style="width: 3%;"></div>
                </div>
              </div>
              <div class="ios-app-ratings-bar-row">
                <span class="ios-app-rating-stargraph-stars ios-app-rating-stargraph-stars-2"></span>
                <div class="ios-app-rating-bargraph-bar">
                  <div class="ios-app-rating-bargraph-bar-foreground" style="width: 2%;"></div>
                </div>
              </div>
              <div class="ios-app-ratings-bar-row">
                <span class="ios-app-rating-stargraph-stars "></span>
                <div class="ios-app-rating-bargraph-bar">
                  <div class="ios-app-rating-bargraph-bar-foreground" style="width: 1%;"></div>
                </div>
              </div>
            </figure>
            <h5 class="ios-app-rating-count">298 个评分</h5>
          </div>
        </div>
        <div class="ios-app-comments-list">
        </div>
      </section>

      <section class="l-content-width section w-100">
      <!-- <section class="l-content-width section"> -->
        <div class="banner" id="screenshotTableContainer">
          <!-- <div class="swiper-container swiper-container-initialized swiper-container-horizontal"> -->
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <!-- <div class="swiper-slide blue-slide" style="width: 1883px; margin-right: 20px;"> -->
              <div class="swiper-slide">
                <div class="col-md-2 col-sm-3 delete">
                <!-- <div class="col-md-2 col-sm-3 delete" data-key="b214ff9d83d34efb87d20764d2357a0a" data-index="0"> -->
                  <div class="pl" style="background: #f0f0f8;">
                    <h3 style="font-size:15px;color: #000;">喜欢的不得了<span class="time" style="font-size:14px;">周四</span></h3>
                    <p class="plx" style="padding:0px 0 2px 0;">
                      <img src="../assets/img/xing.png" style="width: 12px;height: 12px;" alt="" />
                      <img src="../assets/img/xing.png" style="width: 12px;height: 12px;" alt="" />
                      <img src="../assets/img/xing.png" style="width: 12px;height: 12px;" alt="" />
                      <img src="../assets/img/xing.png" style="width: 12px;height: 12px;" alt="" />
                      <img src="../assets/img/xing.png" style="width: 12px;height: 12px;" alt="" />
                    </p>
                    <p class="p-text">我们朋友几个都喜欢玩，我真的是很早就开始接触这个app了，认识了很多朋友。</p>
                  </div>
                </div>
              </div>
              <!-- <div class="swiper-slide">
                <div class="col-md-2 col-sm-3 delete">
                  <div class="pl" style="background: #f0f0f8;">
                    <h3 style="font-size:15px;color: #000;">非常好玩<span class="time" style="font-size:14px;">周三</span></h3>
                    <p class="plx" style="padding:4px 0 8px 0;">
                      <img src="../assets/img/xing.png" style="width: 12px;height: 12px;" alt="" />
                      <img src="../assets/img/xing.png" style="width: 12px;height: 12px;" alt="" />
                      <img src="../assets/img/xing.png" style="width: 12px;height: 12px;" alt="" />
                      <img src="../assets/img/xing.png" style="width: 12px;height: 12px;" alt="" />
                      <img src="../assets/img/xing.png" style="width: 12px;height: 12px;" alt="" />
                    </p>
                    <p class="p-text">真心不错</p>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </section>

      <section class="l-content-width section section-bordered">
        <h2 class="section-headline">新功能</h2>
        <div class="ios-app-whatsnew-content ios-app-whatsnew-latest">
          <p class="banb">版本 {{ bifrost.appVersion }}<span>5天前</span></p>
        </div>
        <div class="ios-app-whatsnew-content">
          <div class="ellipsis">
            <div class="ellipsis-container">
              <div class="ellipsis-content f-game-content2" style="height: 43px;">
                <p>{{ bifrost.feature }}</p>
              </div>
              <!-- <div class="ellipsis-ghost expand"> -->
              <div>
                <div class="ellipsis-more">更多</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="l-content-width section section-bordered">
        <h2 class="section-headline">信息</h2>
        <dl class="ios-app-infor-list">
          <div class="ios-app-infor-list-row row-bottom-border">
            <dt class="ios-app-infor-list-row-term">开发商</dt>
            <dd class="ios-app-infor-list-row-val">cjtuzi.com</dd>
          </div>
          <div class="ios-app-infor-list-row row-bottom-border">
            <dt class="ios-app-infor-list-row-term">大小</dt>
            <dd class="ios-app-infor-list-row-val">{{ bifrost.apkSize }}MB</dd>
          </div>
          <div class="ios-app-infor-list-row row-bottom-border">
            <dt class="ios-app-infor-list-row-term">类别</dt>
            <dd class="ios-app-infor-list-row-val">其它</dd>
          </div>
          <div class="ios-app-infor-list-row row-bottom-border">
            <dt class="ios-app-infor-list-row-term">兼容性</dt>
            <dd class="ios-app-infor-list-row-val">
              <div class="we-clamp"><span>需要 Android 7.0 或更高版本</span></div>
            </dd>
          </div>
          <div class="ios-app-infor-list-row row-bottom-border">
            <dt class="ios-app-infor-list-row-term">语言</dt>
            <dd class="ios-app-infor-list-row-val">
              <div class="we-clamp"><span>简体中文和英文</span></div>
            </dd>
          </div>
          <div class="ios-app-infor-list-row row-bottom-border">
            <dt class="ios-app-infor-list-row-term">年龄分级</dt>
            <dd class="ios-app-infor-list-row-val">限18岁以上</dd>
          </div>
          <div class="ios-app-infor-list-row row-bottom-border">
            <dt class="ios-app-infor-list-row-term">Copyright</dt>
            <dd class="ios-app-infor-list-row-val">{{ bifrost.cname }}</dd>
          </div>
          <div class="ios-app-infor-list-row row-bottom-border">
            <dt class="ios-app-infor-list-row-term">价格</dt>
            <dd class="ios-app-infor-list-row-val">免费</dd>
          </div>
          <div class="ios-app-infor-list-row">
            <dt class="ios-app-infor-list-row-term"><a href="javascript:;">隐私政策</a></dt>
          </div>
        </dl>
      </section>
    </div>
    <div class="disclaimer">
      免责声明：<br>本网站仅为开发者提供App的下载和安装托管，App内的内容和运营相关事项由App开发者负责，与本网站无关。
    </div>
    <div class="tips">
    </div>

    <!--弹框-->
    <!-- <div class="mask">
      <div class="elasticbox">
        <i class="downClose"></i>
        <div class="flexslider-box">
          <div class="flexslider" style="">
            <ul class="slides">
              <li class="flex-active-slide"
                style="width: 100%; float: left; margin-right: -100%; position: relative; opacity: 1; display: block; z-index: 2;">
                <a href="http://192.168.1.104:4000/index.html"><img src="../assets/img/install-profile-tips_0.png"
                    draggable="false" alt="" /></a>
                <div class="swipe-wrap-con">
                  <p class="font18">安装引导</p>
                  <p class="font15">第一步 允许打开配置描述文件</p>
                </div>
              </li>
              <li
                style="width: 100%; float: left; margin-right: -100%; position: relative; opacity: 0; display: block; z-index: 1;">
                <a href="http://192.168.1.104:4000/index.html"><img src="../assets/img/install-profile-tips_1.png"
                    draggable="false" alt="" /></a>
                <div class="swipe-wrap-con">
                  <p class="font18">安装引导</p>
                  <p class="font15">第二步 点击右上角安装按钮</p>
                </div>
              </li>
              <li
                style="width: 100%; float: left; margin-right: -100%; position: relative; opacity: 0; display: block; z-index: 1;">
                <a href="http://192.168.1.104:4000/index.html"><img src="../assets/img/install-profile-tips_2.png"
                    draggable="false" alt="" /></a>
                <div class="swipe-wrap-con">
                  <p class="font18">安装引导</p>
                  <p class="font15">第三步 输入开机解锁密码</p>
                </div>
              </li>
              <li
                style="width: 100%; float: left; margin-right: -100%; position: relative; opacity: 0; display: block; z-index: 1;">
                <a href="http://192.168.1.104:4000/index.html"><img src="../assets/img/install-profile-tips_3.png"
                    draggable="false" alt="" /></a>
                <div class="swipe-wrap-con">
                  <p class="font18">安装引导</p>
                  <p class="font15">第四步 点击下方安装按钮</p>
                </div>
              </li>
            </ul>
            <ol class="flex-control-nav flex-control-paging">
              <li><a class="flex-active">1</a></li>
              <li><a>2</a></li>
              <li><a>3</a></li>
              <li><a>4</a></li>
            </ol>
            <ul class="flex-direction-nav">
              <li><a class="flex-prev" href="http://192.168.1.104:4000#">Previous</a></li>
              <li><a class="flex-next" href="http://192.168.1.104:4000#">Next</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div> -->
    <!--弹框2-->
    <!-- <div class="mask2">
      <div class="elasticbox">

        <div class="top"><img src="res/safari-tip_banner_en.png" alt="" /></div><i class="downClose"></i>
        <div class="text-box">
          <input type="text" id="foo" value="">
          <button type="button" class="safari-copy safari-copy_text codybtn" data-clipboard-target="#foo"
            name="button"></button>
        </div>
      </div>
    </div> -->
    <div class="browser_tips" v-show="this.isWeiXin()">
      <img src="../assets/img/tips_weixin_ios.png" alt="" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LoadingFile from '@/components/LoadingFile.vue'
import RedirectIOS from '@/components/RedirectIOS.vue'
import CryptoJS from 'crypto-js';
import { mapMutations } from "vuex";

// const bifrost = {
//   'ks':{title:'凯晟', iOSPath:'http://sij02478.cdn.safe.expsu.live/tttE2k?type=1'},
//   'hongxin':{title:'弘鑫', iOSPath:'http://aiwnovx0.cdn.safe.expsu.live/8CUhK9?type=1'},
//   'hongsheng':{title:'弘升', iOSPath:'http://deidejk4.cdn.safe.expsu.live/EfmiwT?type=1'},
//   'jucai':{title:'集彩', iOSPath:'http://hstyvy01.cdn.safe.expsu.live/O9GLod?type=1'},
//   'zhongbo':{title:'众博体育', iOSPath:'http://dpckovx7.cdn.safe.expsu.live/2dzNcV?type=1'},
//   'hongrun':{title:'弘润', iOSPath:'http://lpxafjt1.cdn.safe.expsu.live/2X59HY?type=1'},
//   'shenhaohui':{title:'绅豪汇', iOSPath:'http://phouy025.cdn.safe.expsu.live/qG5S1p?type=1'},
//   'wanhao':{title:'万豪', iOSPath:'http://bjpbdf04.cdn.safe.expsu.live/ubF6Vo?type=1'},
//   'zhongfu':{title:'中福', iOSPath:'http://aepfl124.cdn.safe.expsu.live/sSgc2o?type=1'},
//   'ctd':{title:'彩天地', iOSPath:'http://bbdnqtx6.cdn.safe.expsu.live/V2NB2m?type=1'},
//   'boyue':{title:'博越', iOSPath:''},
//   'wanli':{title:'万利', iOSPath:''},
// };
const KEY = 'VKbSX8VYqGWGtZxmduQB9Ph6';
function enDES3(str, key = KEY) {
  const encrypt = CryptoJS.TripleDES.encrypt(str, CryptoJS.enc.Utf8.parse(key), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  let result = encrypt.toString();
  result = result.replace(/\//g, '_');
  return result;
}

function deDES3(str, key = KEY) {
  str = str.replace(/_/g, '/');
  const decrypt = CryptoJS.TripleDES.decrypt(str, CryptoJS.enc.Utf8.parse(key), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  let result = decrypt.toString(CryptoJS.enc.Utf8);
  return result;
}

export default {
  name: 'Apps',
  data() {
    return {
      bifrost: {},
      qlserver: process.env.VUE_APP_QLSERVER,
      mgserver: process.env.VUE_APP_MGSERVER,
      npfserver: process.env.VUE_APP_NPFSERVER,
      appID: this.$route.params.id,
      apkPath: '',
      iconUrl: `/static/icons/${this.$route.params.id}.png`,
    }
  },
  async created() {
    let loader = this.$loading.show({}, {
      after: this.$createElement(RedirectIOS)
    })
    let res = await this.getAppInfo(this.appID)
    this.bifrost = JSON.parse(deDES3(res.data['data']))
    if (this.bifrost === null) {
      window.location.href = window.location.hostname
    } else {
      if (this.isIos()) {
        let ipaUri = this.bifrost.ipaUri
        if (ipaUri) {
          window.location.href = ipaUri
        }
      } else {
        this.apkPath = `//${window.location.host}/static/apks/${this.$route.params.id}-${this.bifrost.appVersion}.apk`
        loader.hide()
      }
    }
  },
  methods: {
    ...mapMutations(['loadingState']),
    getAppInfo(appID) {
      // this.loader = this.$loading.show({});
      try {
        const server = appID === 'mgComics' ? this.mgserver : this.qlserver;
        let response = axios.get(`${server}/chaux9ch/`+encodeURIComponent(enDES3(JSON.stringify(`${appID}`))), {'Content-Type': 'application/json'})
        return response
      } catch (err) {
        //console.log('發生錯誤', err)
        return null
      }
    },
    isIos() {
      let isIos = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      return isIos
    },
    isAndroid() {
      let isAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1
      return isAndroid
    },
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true
      } else {
        return false
      }
    },
    chromeForceFileDownload(response){
      //瀏覽器有支援createObjectURL，可使用下載並顯示百分比的功能(Chrome、Firefox...等等)
      const url = window.URL.createObjectURL(new Blob([response.data], {type: response.data.type}))
      const link = document.createElement('a')
      link.href = url
      let apkName = `${this.$route.params.id}-${this.bifrost.appVersion}.apk`
      link.setAttribute('download', apkName)
      document.body.appendChild(link)
      link.click()
      link.remove();
      window.URL.revokeObjectURL(url);
    },
    forceFileDownload(){
      //不支援createObjectURL的瀏覽器，改用其他方式下載
      //console.log(response)
      //const url = window.URL.createObjectURL(new Blob([response.data], {type: response.data.type}))
      const link = document.createElement('a')
      link.href = this.apkPath
      let apkName = `${this.$route.params.id}-${this.bifrost.appVersion}.apk`
      link.setAttribute('download', apkName)
      document.body.appendChild(link)
      link.click()
      link.remove();
      //window.URL.revokeObjectURL(url);
    },
    downloadAPK(){
      this.$modal.show('dialog', {
        text: '开始下载软件？',
        width: '300',
        maxWidth: '300',
        buttons: [
          {
            title: '取消'
          },
          {
            title: '下载',
            default: true,
            handler: () => {
              /**
               * 下載統計
               */
              const docUrl = document.location.href;
              const urlArr = docUrl.split("?");
              if(urlArr.length > 1 && urlArr[1].indexOf('lk=') > -1) {
                // const hostname = (new URL(document.location)).hostname;
                const lk = parseInt(urlArr[1].replace('lk=', ''));
                let url = this.npfserver + '/member/frontend/comic/installation';
                if(lk) {
                  const config = {
                    headers: {
                      Domain: '57303.com',
                    }
                  }
                  axios.post(url, {
                    lk
                  }, config);
                  // .then(() => console.log('success'))
                  // .cathc(err => console.log('error', err));
                }
              }
              /** 下載統計 end */
              // Chrome、Firefox...等等瀏覽器有支援createObjectURL的才顯示下載百分比，其他顯示(即将完成)一秒後消失直接進行下載
              if(this.getBrowserType()==='Chrome' ||
                 this.getBrowserType()==='Firefox'||
                 this.getBrowserType()==='Opera'  ||
                 this.getBrowserType()==='Puffin' ||
                 this.getBrowserType()==='Edge'
              ){
                let loader = this.$loading.show({},{
                  after: this.$createElement(LoadingFile)
                })
                axios({
                  method: 'get',
                  url: this.apkPath,
                  responseType: 'blob',
                  onDownloadProgress: (progressEvent) => {
                    this.loadingState(progressEvent)
                    // let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    // console.log(progressEvent.lengthComputable)
                    // console.log(percentCompleted)
                  },
                }).then(response => {
                  this.$modal.hide('dialog')
                  this.chromeForceFileDownload(response)
                  loader.hide()
                }).catch(e => {
                  alert(e.message);
                  this.$modal.hide('dialog');
                  loader.hide()
                })
              }else{
                let loader = this.$loading.show({},{
                  after: this.$createElement(LoadingFile)
                })
                this.$modal.hide('dialog')
                this.forceFileDownload()
                //下載提示維持顯示1秒
                setTimeout(function(){ loader.hide(); }, 1000);
              }
            }
          }
        ]
      })
    },
    getBrowserType(){//判断浏览器类型
      let userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
      if (userAgent.indexOf("Firefox") > -1) {
          return "Firefox";
      } else if (userAgent.indexOf("Edge") > -1 || userAgent.indexOf("EdgA") > -1) {
          return "Edge";
      } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
          return "Opera";
      } else if (userAgent.indexOf("Maxthon") > -1) {//mx5(傲遊)
          return "Maxthon";
      } else if (userAgent.indexOf("QQBrowser") > -1) {
          return "QQBrowser";
      } else if (userAgent.indexOf("baidu") > -1 ) {
          return "baidu";
      } else if (userAgent.indexOf("Sogou") > -1) {
          return "Sogou";
      } else if (userAgent.indexOf("DuckDuckGo") > -1 ) {
          return "DuckDuckGo";
      } else if (userAgent.indexOf("Mint") > -1) {
          return "Mint";
      } else if (userAgent.indexOf("XiaoMi") > -1) {
          return "XiaoMi";
      } else if (userAgent.indexOf("UCBrowser") > -1) {
          return "UC";
      } else if (userAgent.indexOf("360SE") > -1) {
          return "360SE";
      } else if (userAgent.indexOf("HuaweiBrowser") > -1 ) {
          return "Huawei";
      } else if (userAgent.indexOf("Puffin") > -1) {
          return "Puffin";
      } else if (userAgent.indexOf("VivoBrowser") > -1 ) {
          return "Vivo";
      } else if (userAgent.indexOf("Version") > -1) {//pure、dolphin
          return "pure";
      } else if (userAgent.indexOf("Chrome") > -1) {
          return "Chrome";
      } else if (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident") > -1) {
          return "IE";
      }else{
        return "etc";
      }
    }
    // setBifrost() {
    // }
  },

}
</script>

<style lang="scss" scoped>
@import "@/styles/apps.scss";

.plxing p {
  display: inline-block;
}

.plxing p img {
  width: 14px;
  height: 14px;
  float: left;
  margin-top: 5px;
  margin-left: 4px;
}

.downloadBtn {
  width: 76px;
  height: 24px;
  line-height: 24px;
  border-radius: 30px;
  color: #fff;
  background-color: #3478f6;
}

#tips {
  margin-top: 35px;
  font-size: 12px;
  color: #ababae;
  margin-left: 5px;
}

.browser_tips {
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 100%;
  z-index: 100;
}

.swiper-container {
    height: auto;
}
</style>
